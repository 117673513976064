<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import { QrcodeStream } from "vue-qrcode-reader";
import Dropdown from "primevue/dropdown";
import {
  dateFormate,
  getCurrentTimeZone,
  convertToUTC8,
} from "../tool/formate";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import OverlayPanel from "primevue/overlaypanel";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import EmployerView from "./Employer.vue";
import Chip from "primevue/chip";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import Toast from "primevue/toast";
import User from "../api/salary/auth/user";
import AdminShift from "../api/salary/admin/shift";
import AdminEmployer from "../api/salary/admin/employer";
import Organisation from "../api/salary/admin/organisation";
import AdminEmployerShift from "../api/salary/admin/employer_shift_relation";
import ManagerEmployerShift from "../api/salary/manager/employer_shift_relation";
import SelfEmployerShift from "../api/salary/self/employer_shift_relation";

import { useI18n } from "../i18nPlugin";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import "@fullcalendar/core";
import { formatDate } from "@fullcalendar/core";
// import momentTimezonePlugin from "@fullcalendar/moment-timezone";

const selectedDevice = ref(null);
const devices = ref([]);
const dt = ref();
const fullCalendar = ref();
const op = ref();
let userApi = new User();
let admin_shiftAPI = new AdminShift();
let admin_employerAPI = new AdminEmployer();

let organisationAPI = new Organisation();
let admin_employer_shiftAPI = new AdminEmployerShift();
let manager_employer_shiftAPI = new ManagerEmployerShift();
let self_employer_shiftAPI = new SelfEmployerShift();

const i18n = useI18n();
const toast = useToast();
const store = useStore();

const data = reactive({
  excel: { data: null },
  OrganisationSearch: "",
  selectOrganisationList: [],
  employer_organisation_id: "",
  date_confirm_by_date: null,
  date_confirm: null,
  search_date: new Date(),
  AddShiftSearch: "",
  selectShiftList: [],
  fastDialog: false,
  shift_contents: [],
  shift_content: null,
  shift_content_title: "",
  selectOrganisationList: [],
  options: {
    locale:
      store.state.lang == "cn"
        ? "zh-tw"
        : store.state.lang == "en"
        ? "en-us"
        : "zh-tw",
    //ocale: "en-us",
    // timeZone: "en-us",
    // timezone: false,
    // timezone: "local",

    // timeZone: "UTC",
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      // momentTimezonePlugin,
    ],
    // timeZone: "America/New_York",
    // initialDate:
    //   new Date().getFullYear() +
    //   "-" +
    //   (new Date().getMonth() + 1) +
    //   "-" +
    //   new Date().getDate(),
    dateClick: handleDateClick,
    customButtons: {
      prev: {
        // this overrides the prev button
        text: "PREV",
        click: () => {
          let calendarApi = fullCalendar.value.getApi();
          calendarApi.prev();
          //   console.log(
          //     fullCalendar.value.calendar.currentData.dateProfile.currentDate
          //   );
          data.search_date =
            fullCalendar.value.calendar.currentData.dateProfile.currentDate;
        },
      },
      next: {
        // this overrides the next button
        text: "PREV",
        click: () => {
          let calendarApi = fullCalendar.value.getApi();
          calendarApi.next();
          data.search_date =
            fullCalendar.value.calendar.currentData.dateProfile.currentDate;
        },
      },
    },
    headerToolbar: {
      left: "prev,next today",
      center: "title",
      right: "dayGridMonth",
      //   ,timeGridWeek,timeGridDay
    },
    buttonText: {
      month: "月",
      week: "週",
      day: "日",
    },
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    events: null,
  },
});
const beforemounted = onBeforeMount(async () => {});

function handleDateClick(arg) {
  data.shift_content_title = arg.dateStr;
  data.shift_contents = [];
  // if (data.options.events != null)
  //   for (let i = 0; i < data.options.events.length; i++) {
  //     if (
  //       dateFormate(data.options.events[i].start, "YYYY-MM-DD") ==
  //       dateFormate(arg.dateStr, "YYYY-MM-DD")
  //     ) {
  //       data.shift_contents.push(data.options.events[i]);
  //     }
  //   }
  if (data.contet_show != null)
    for (let i = 0; i < data.contet_show.length; i++) {
      if (
        dateFormate(data.contet_show[i].start_time, "YYYY-MM-DD") ==
          dateFormate(arg.dateStr, "YYYY-MM-DD") &&
        (data.shift_select == null ||
          (data.shift_select != null &&
            data.shift_select.id == data.contet_show[i].shift_id))
      ) {
        data.shift_contents.push(data.contet_show[i]);
      }
    }

  data.AddShiftSearch = "";
  data.chooseShiftDialog = true;
  data.selectedShiftRelation = [];
  op.value.hide();
}

async function Excel() {
  data.excel.data = [];
  console.log(data.shift_contents);
  for (let i = 0; i < data.shift_contents.length; i++) {
    await data.excel.data.push({
      time:
        dateFormate(data.shift_contents[i].start_time, "hh:mm") +
        "-" +
        dateFormate(data.shift_contents[i].end_time, "hh:mm"),
      people: data.shift_contents[i].title.split("(")[1].replace(")", ""),
      people_english: data.shift_contents[i].employer_english,
      shift: data.shift_contents[i].title.split("(")[0],
    });
  }

  dt.value.exportCSV();
  console.log(data.shift_contents);
}

function getFormattedTimeZoneOffset() {
  const offsetInMinutes = new Date().getTimezoneOffset(); // 得到當前時區偏移量，單位為分鐘
  const sign = offsetInMinutes <= 0 ? "+" : "-"; // 根據偏移量的正負號決定時區符號
  const absoluteOffset = Math.abs(offsetInMinutes);
  const hours = String(Math.floor(absoluteOffset / 60)).padStart(2, "0"); // 小時部分
  const minutes = String(absoluteOffset % 60).padStart(2, "0"); // 分鐘部分

  return `${sign}${hours}:${minutes}`;
}

function getShift() {
  let tmp_ids = [];
  for (let i = 0; i < store.state.bouns_employer.length; i++) {
    tmp_ids.push(store.state.bouns_employer[i].id);
  }
  // console.log("------");
  // console.log(data.search_date);
  // console.log("------");
  let tmp_search_date = dateFormate(data.search_date, "normal");
  let start = new Date(
    tmp_search_date.getFullYear(),
    tmp_search_date.getMonth(),
    0,
    0,
    0
  );
  let end = new Date(
    tmp_search_date.getFullYear(),
    tmp_search_date.getMonth() + 1,
    25,
    23,
    59,
    59
  );
  if (store.state.user.role == 1) {
    admin_employer_shiftAPI
      .getEmployerShiftRelationList(
        start.toISOString(),
        end.toISOString(),
        tmp_ids,
        "",
        data.employer_organisation_id,
        1,
        60000
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          let tmp_data = res.data.data.datas;
          let tmp = [];
          let tmp_show = [];
          if (tmp_data != null)
            for (let i = 0; i < tmp_data.length; i++) {
              for (
                let j = 0;
                j < tmp_data[i].employer_shift_relations.length;
                j++
              ) {
                let tmp_date = new Date(
                  tmp_data[i].employer_shift_relations[j].date
                );
                tmp_date = dateFormate(tmp_date, "normal");
                let tmp_start = new Date(
                  tmp_data[i].employer_shift_relations[j].shift.start_time
                );
                tmp_start = dateFormate(tmp_start, "normal");
                let tmp_end = new Date(
                  tmp_data[i].employer_shift_relations[j].shift.end_time
                );
                tmp_end = dateFormate(tmp_end, "normal");

                let start_date = new Date(
                  tmp_date.getFullYear(),
                  tmp_date.getMonth(),
                  tmp_date.getDate(),
                  tmp_start.getHours(),
                  tmp_start.getMinutes()
                );
                let end_date = new Date(
                  tmp_date.getFullYear(),
                  tmp_date.getMonth(),
                  tmp_date.getDate(),
                  tmp_end.getHours(),
                  tmp_end.getMinutes()
                );

                let tmp_tmp = new Date(start_date);
                start_date = dateFormate(start_date, "normal_show");
                end_date = dateFormate(end_date, "normal_show");

                tmp.push({
                  //   id: tmp_data[i].employer_shift_relations[j].shift_id,
                  shift_id: tmp_data[i].employer_shift_relations[j].shift_id,
                  title:
                    tmp_data[i].employer_shift_relations[j].shift.name +
                    "(" +
                    tmp_data[i].employer_name +
                    ")",
                  date: tmp_data[i].employer_shift_relations[j].date,
                  start: tmp_tmp,
                  start_time: start_date,
                  // start: start_date,
                  // end: end_date,
                  end_time: end_date,
                  employer_id: tmp_data[i].employer_id,
                  employer_english: tmp_data[i].employer_english_name,
                  status: tmp_data[i].employer_shift_relations[j].status,
                  stage: tmp_data[i].employer_shift_relations[j].stage,
                });

                const tmp_showModify = tmp_show.find(
                  (person) =>
                    person.shift_id ===
                      tmp_data[i].employer_shift_relations[j].shift_id &&
                    person.start_time.getTime() === start_date.getTime() &&
                    person.end_time.getTime() === end_date.getTime()
                );
                if (tmp_showModify) {
                  tmp_showModify.count = tmp_showModify.count + 1;
                  tmp_showModify.title =
                    tmp_showModify.title.split("|")[0] +
                    "|" +
                    tmp_showModify.count +
                    "💁";
                } else {
                  tmp_show.push({
                    shift_id: tmp_data[i].employer_shift_relations[j].shift_id,
                    title:
                      tmp_data[i].employer_shift_relations[j].shift.name +
                      "|" +
                      "1" +
                      "💁",
                    count: 1,

                    date: tmp_data[i].employer_shift_relations[j].date,
                    start: tmp_tmp,
                    start_time: start_date,
                    // start: start_date,

                    end_time: end_date,
                    // end: end_date,
                    employer_id: tmp_data[i].employer_id,
                    employer_english: tmp_data[i].employer_english_name,
                    status: tmp_data[i].employer_shift_relations[j].status,
                    stage: tmp_data[i].employer_shift_relations[j].stage,
                  });
                }
              }
            }

          // console.log(tmp);

          if (store.state.shift_search != "shift") {
            data.options.events = tmp;
          } else {
            data.options.events = tmp_show;
          }

          data.contet_show = tmp;
          // data.events = tmp;
          console.log(data.options.events);
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  } else {
    manager_employer_shiftAPI
      .getEmployerShiftRelationList(
        start.toISOString(),
        end.toISOString(),
        tmp_ids,
        "",
        data.employer_organisation_id,
        1,
        60000
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          let tmp_data = res.data.data.datas;
          let tmp = [];
          let tmp_show = [];
          if (tmp_data != null)
            for (let i = 0; i < tmp_data.length; i++) {
              for (
                let j = 0;
                j < tmp_data[i].employer_shift_relations.length;
                j++
              ) {
                let tmp_date = new Date(
                  tmp_data[i].employer_shift_relations[j].date
                );
                tmp_date = dateFormate(tmp_date, "normal");
                let tmp_start = new Date(
                  tmp_data[i].employer_shift_relations[j].shift.start_time
                );
                tmp_start = dateFormate(tmp_start, "normal");
                let tmp_end = new Date(
                  tmp_data[i].employer_shift_relations[j].shift.end_time
                );
                tmp_end = dateFormate(tmp_end, "normal");

                let start_date = new Date(
                  tmp_date.getFullYear(),
                  tmp_date.getMonth(),
                  tmp_date.getDate(),
                  tmp_start.getHours(),
                  tmp_start.getMinutes()
                );
                let end_date = new Date(
                  tmp_date.getFullYear(),
                  tmp_date.getMonth(),
                  tmp_date.getDate(),
                  tmp_end.getHours(),
                  tmp_end.getMinutes()
                );

                let tmp_tmp = new Date(start_date);
                start_date = dateFormate(start_date, "normal_show");
                end_date = dateFormate(end_date, "normal_show");

                tmp.push({
                  //   id: tmp_data[i].employer_shift_relations[j].shift_id,
                  shift_id: tmp_data[i].employer_shift_relations[j].shift_id,
                  title:
                    tmp_data[i].employer_shift_relations[j].shift.name +
                    "(" +
                    tmp_data[i].employer_name +
                    ")",
                  date: tmp_data[i].employer_shift_relations[j].date,
                  start: tmp_tmp,
                  start_time: start_date,
                  // start: start_date,
                  // end: end_date,
                  end_time: end_date,
                  employer_id: tmp_data[i].employer_id,
                  employer_english: tmp_data[i].employer_english_name,
                  status: tmp_data[i].employer_shift_relations[j].status,
                  stage: tmp_data[i].employer_shift_relations[j].stage,
                });

                const tmp_showModify = tmp_show.find(
                  (person) =>
                    person.shift_id ===
                      tmp_data[i].employer_shift_relations[j].shift_id &&
                    person.start_time.getTime() === start_date.getTime() &&
                    person.end_time.getTime() === end_date.getTime()
                );
                if (tmp_showModify) {
                  tmp_showModify.count = tmp_showModify.count + 1;
                  tmp_showModify.title =
                    tmp_showModify.title.split("|")[0] +
                    "|" +
                    tmp_showModify.count +
                    "💁";
                } else {
                  tmp_show.push({
                    shift_id: tmp_data[i].employer_shift_relations[j].shift_id,
                    title:
                      tmp_data[i].employer_shift_relations[j].shift.name +
                      "|" +
                      "1" +
                      "💁",
                    count: 1,

                    date: tmp_data[i].employer_shift_relations[j].date,
                    start: tmp_tmp,
                    start_time: start_date,
                    // start: start_date,

                    end_time: end_date,
                    // end: end_date,
                    employer_id: tmp_data[i].employer_id,
                    employer_english: tmp_data[i].employer_english_name,
                    status: tmp_data[i].employer_shift_relations[j].status,
                    stage: tmp_data[i].employer_shift_relations[j].stage,
                  });
                }
              }
            }

          // console.log(tmp);

          if (store.state.shift_search != "shift") {
            data.options.events = tmp;
          } else {
            data.options.events = tmp_show;
          }

          data.contet_show = tmp;
          // data.events = tmp;
          console.log(data.options.events);
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  }
}

function toggle(event, arg) {
  data.chooseShiftDialog = false;
  data.shift_content = arg;
  console.log(data.shift_content);
  op.value.toggle(event);
}

function ChooseEmployer() {
  store.commit("set_employer_search", "apply_bouns");
  data.chooseDialog = true;
}
function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      store.commit("set_user", res.data.data);
    } else {
      store.commit("set_user", null);
    }
  });
}

const mounted = onMounted(async () => {
  CheckUser();
  getShift();
  if (store.state.user.role == 1) {
    getShiftSelectList();
    getOrganisationSelectList();
  }
  devices.value = (await navigator.mediaDevices.enumerateDevices()).filter(
    ({ kind }) => kind === "videoinput"
  );

  if (devices.value.length > 0) {
    selectedDevice.value = devices.value[0];
  }
  // console.log(fullCalendar);
});

function onDecodeScan(qrCode) {
  data.outJsonScan = qrCode[0].rawValue;
}
function drawOutline(decodeData, context) {
  var points = [];
  for (let i = 0; i < decodeData[0].cornerPoints.length; i++) {
    switch (i) {
      case 0:
        points[0] = decodeData[0].cornerPoints[i];
        break;
      case 1:
        points[1] = decodeData[0].cornerPoints[i];
        break;
      case 2:
        points[2] = decodeData[0].cornerPoints[i];
        break;
      case 3:
        points[3] = decodeData[0].cornerPoints[i];
        break;
      default:
        break;
    }
  }

  context.font = "13px Arial";
  context.fillText(data.outJsonScan, points[0].x, points[0].y);
  context.lineWidth = 5;
  context.strokeStyle = "green";
  context.beginPath();
  context.moveTo(points[0].x, points[0].y);

  for (const { x, y } of points) {
    context.lineTo(x, y);
  }
  context.lineTo(points[0].x, points[0].y);
  context.closePath();
  context.stroke();
}
function onInit(promise) {
  promise
    .then(() => {
      console.log("Successfully initilized! Ready for scanning now!");
    })
    .catch((error) => {
      if (error.name === "NotAllowedError") {
        this.errorMessage = "Hey! I need access to your camera";
      } else if (error.name === "NotFoundError") {
        this.errorMessage = "Do you even have a camera on your device?";
      } else if (error.name === "NotSupportedError") {
        this.errorMessage =
          "Seems like this page is served in non-secure context (HTTPS, localhost or file://)";
      } else if (error.name === "NotReadableError") {
        this.errorMessage =
          "Couldn't access your camera. Is it already in use?";
      } else if (error.name === "OverconstrainedError") {
        this.errorMessage =
          "Constraints don't match any installed camera. Did you asked for the front camera although there is none?";
      } else {
        this.errorMessage = "UNKNOWN ERROR: " + error.message;
      }
    });
}

function getOrganisationSelectList() {
  organisationAPI.getOrganisationSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectOrganisationList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getShiftSelectList() {
  admin_shiftAPI.getShiftSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectShiftList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function FastSetting() {
  data.fastDialog = true;
  data.set_date = null;
  data.AddShiftSearch = "";
}
function SaveShift() {
  let tmp = [];
  console.log(store.state.bouns_employer);
  for (let i = 0; i < store.state.bouns_employer.length; i++) {
    tmp.push({
      employer_id: store.state.bouns_employer[i].id,
      new_shifts: [
        {
          employer_id: store.state.bouns_employer[i].id,
          shift_id:
            data.AddShiftSearch != "" && data.AddShiftSearch != null
              ? data.AddShiftSearch.id
              : 0,
          date: new Date(data.shift_content_title).toISOString(),
        },
      ],
      delete_shift_ids: [],
      delete_shift_dates: [],
    });
  }

  admin_employer_shiftAPI.editEmployerShiftRelation(tmp).then((res) => {
    if (res.data.error_code == 0) {
      getShift();
      data.chooseShiftDialog = false;
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "設定成功",
        life: 3000,
      });
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function SaveFastShift() {
  let tmp = [];
  for (let i = 0; i < store.state.bouns_employer.length; i++) {
    if (data.set_date != null)
      for (let j = 0; j < data.set_date.length; j++) {
        tmp.push({
          employer_id: store.state.bouns_employer[i].id,
          new_shifts: [
            {
              employer_id: store.state.bouns_employer[i].id,
              shift_id:
                data.AddShiftSearch != "" && data.AddShiftSearch != null
                  ? data.AddShiftSearch.id
                  : 0,
              date: data.set_date[j].toISOString(),
            },
          ],
          delete_shift_ids: [],
          delete_shift_dates: [],
        });
      }
  }

  admin_employer_shiftAPI.editEmployerShiftRelation(tmp).then((res) => {
    if (res.data.error_code == 0) {
      getShift();
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "設定成功",
        life: 3000,
      });
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function Remove(shift, check) {
  admin_employer_shiftAPI
    .editEmployerShiftRelation([
      {
        employer_id: check
          ? shift.employer_id
          : shift.extendedProps.employer_id,
        new_shifts: [],
        delete_shift_ids: [
          check ? shift.shift_id : shift.extendedProps.shift_id,
        ],
        delete_shift_dates: [check ? shift.date : shift.date],
      },
    ])
    .then((res) => {
      if (res.data.error_code == 0) {
        getShift();
        data.chooseShiftDialog = false;
        data.deleteDialog = false;
        op.value.hide();
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: "Success Message",
          life: 3000,
        });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

async function RemoveAll() {
  let tmp = [];
  if (data.selectedShiftRelation != null)
    for (let i = 0; i < data.selectedShiftRelation.length; i++) {
      await tmp.push({
        employer_id: data.selectedShiftRelation[i].employer_id,
        new_shifts: [],
        delete_shift_ids: [data.selectedShiftRelation[i].shift_id],
        delete_shift_dates: [data.selectedShiftRelation[i].date],
      });
    }

  admin_employer_shiftAPI.editEmployerShiftRelation(tmp).then((res) => {
    if (res.data.error_code == 0) {
      getShift();
      data.chooseShiftDialog = false;
      data.deleteAllDialog = false;
      op.value.hide();
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "Success Message",
        life: 3000,
      });
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

watch(
  () => store.state.bouns_employer,
  () => {
    getShift();
  },
  { deep: true }
);

watch(
  () => data.search_date,
  () => {
    getShift();
  }
);

watch(
  () => data.AddShiftSearch,
  () => {
    if (data.contet_show != null) {
      data.shift_contents = [];
      for (let i = 0; i < data.contet_show.length; i++) {
        if (
          dateFormate(data.contet_show[i].start_time, "YYYY-MM-DD") ==
            dateFormate(data.shift_content_title, "YYYY-MM-DD") &&
          (data.AddShiftSearch == null ||
            data.AddShiftSearch == "" ||
            (data.AddShiftSearch != null &&
              data.AddShiftSearch != "" &&
              data.AddShiftSearch.id == data.contet_show[i].shift_id))
        ) {
          data.shift_contents.push(data.contet_show[i]);
        }
      }
    }
  }
);

watch(
  () => data.OrganisationSearch,
  () => {
    data.employer_organisation_id =
      data.OrganisationSearch == null ? "" : data.OrganisationSearch.id;
    getShift();
  }
);

watch(
  () => data.outJsonScan,
  async () => {
    if (data.outJsonScan != "") {
      try {
        let qrcode = await JSON.parse(data.outJsonScan);
        admin_employerAPI.getEmployerInfo(qrcode.id).then(async (res) => {
          if (res.data.error_code == 0) {
            let employer = await res.data.data;
            data.employer = await res.data.data;
            if (
              store.state.bouns_employer
                .map((e) => e.id)
                .indexOf(employer.id) == -1
            ) {
              store.state.bouns_employer.push(employer);
            }
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      } catch (d) {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "Wrong Template",
          life: 3000,
        });
      }
    }
  }
);

function SettingConfirm(
  check_by_date,
  status,
  stage,
  employer_ids,
  shift_ids,
  shift_date
) {
  switch (check_by_date) {
    case 1:
      if (data.date_confirm_by_date != null) {
        let start = new Date(
          data.date_confirm_by_date.getFullYear(),
          data.date_confirm_by_date.getMonth(),
          data.date_confirm_by_date.getDate(),
          0,
          0
        );
        let end = new Date(
          data.date_confirm_by_date.getFullYear(),
          data.date_confirm_by_date.getMonth(),
          data.date_confirm_by_date.getDate(),
          23,
          59
        );
        admin_employer_shiftAPI
          .editEmployerShiftStatus(
            start,
            end,
            employer_ids,
            shift_ids,
            status,
            stage
          )
          .then((res) => {
            if (res.data.error_code == 0) {
              getShift();
              toast.add({
                severity: "success",
                summary: "Success Message",
                detail: "啟動成功",
                life: 3000,
              });
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "請選擇日期",
          life: 3000,
        });
      }
      break;
    case 2:
      if (data.date_confirm != null) {
        let start = new Date(
          data.date_confirm.getFullYear(),
          data.date_confirm.getMonth(),
          1,
          0,
          0
        );
        let end = new Date(
          data.date_confirm.getFullYear(),
          data.date_confirm.getMonth() + 1,
          0,
          23,
          59
        );
        admin_employer_shiftAPI
          .editEmployerShiftStatus(
            start,
            end,
            employer_ids,
            shift_ids,
            status,
            stage
          )
          .then((res) => {
            if (res.data.error_code == 0) {
              getShift();
              toast.add({
                severity: "success",
                summary: "Success Message",
                detail: "啟動成功",
                life: 3000,
              });
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "請選擇日期",
          life: 3000,
        });
      }
      break;
    case 3:
      if (shift_date != "") {
        let start = new Date(
          shift_date.getFullYear(),
          shift_date.getMonth(),
          shift_date.getDate(),
          0,
          0
        );
        let end = new Date(
          shift_date.getFullYear(),
          shift_date.getMonth(),
          shift_date.getDate(),
          23,
          59
        );
        admin_employer_shiftAPI
          .editEmployerShiftStatus(
            start,
            end,
            employer_ids,
            shift_ids,
            status,
            stage
          )
          .then((res) => {
            if (res.data.error_code == 0) {
              getShift();
              data.chooseShiftDialog = false;
              op.value.hide();
              toast.add({
                severity: "success",
                summary: "Success Message",
                detail: "啟動成功",
                life: 3000,
              });
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "請選擇日期",
          life: 3000,
        });
      }
      break;
  }
}

function confirmDelete(employer) {
  const indexToRemove = store.state.bouns_employer.findIndex(
    (e) => e.id === employer.id
  );
  if (indexToRemove !== -1) {
    store.state.bouns_employer.splice(indexToRemove, 1);
  }
}

function ChooseEmployerByScan() {
  data.scanDialog = true;
  data.employer = null;
}
</script>

<template>
  <Toast />
  <div class="p-mt-2">
    <div
      :style="store.state.shift_search == 'shift' ? 'margin-top: 90px' : ''"
      class="p-text-left"
    >
      <div
        v-if="store.state.shift_search == 'shift' && store.state.user.role == 1"
        style="background: lightgray"
        class="p-col-12"
      >
        {{ i18n.$t("EmployerShift").Organisation }}
      </div>
      <div
        v-if="store.state.shift_search == 'shift' && store.state.user.role == 1"
        class="p-col-12"
      >
        <Dropdown
          v-model="data.OrganisationSearch"
          :options="data.selectOrganisationList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Employer').EmployerTable.Organisation.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div
        v-if="store.state.shift_search == 'shift'"
        style="background: lightgray"
        class="p-col-12"
      >
        {{ i18n.$t("EmployerShift").Employer }}
      </div>
      <div v-if="store.state.shift_search == 'shift'" class="p-col-12">
        <Button
          v-if="store.state.user.role == 1"
          :label="i18n.$t('EmployerShift').ScanEmployer"
          class="p-mr-2 p-button-sm"
          @click="ChooseEmployerByScan"
        />
        <Button
          :label="i18n.$t('EmployerShift').SelectEmployer"
          class="p-mr-2 p-button-sm"
          @click="ChooseEmployer"
        />
        <span style="line-height: 35px">
          <Chip
            :key="index"
            :label="employer.id + ':' + employer.name"
            v-for="(employer, index) in store.state.bouns_employer"
          >
            <span class="ml-2 font-medium">{{
              employer.id + ":" + employer.name
            }}</span>
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-ml-1"
              @click="confirmDelete(employer)"
            />
          </Chip>
        </span>
      </div>
      <div
        v-if="store.state.shift_search == 'shift' && store.state.user.role == 1"
        style="background: lightgray"
        class="p-col-12"
      >
        {{ i18n.$t("EmployerShift").Fast }}
      </div>
      <div
        v-if="store.state.shift_search == 'shift' && store.state.user.role == 1"
        class="p-col-12"
      >
        <Button
          :label="i18n.$t('EmployerShift').Setting"
          class="p-mr-2 p-button-sm"
          @click="FastSetting"
        />
      </div>
      <div style="background: lightgray" class="p-col-12">
        {{ i18n.$t("EmployerShift").Shift }}
      </div>
      <div class="p-col-12">
        <FullCalendar
          ref="fullCalendar"
          :events="data.events"
          :options="data.options"
          aria-haspopup="true"
          aria-controls="overlay_panel"
        >
          <template v-slot:eventContent="arg">
            <b
              :style="
                arg.event.extendedProps.status == 1
                  ? 'Color:red'
                  : arg.event.extendedProps.status == 2 &&
                    arg.event.extendedProps.stage == 2
                  ? 'Color:green'
                  : arg.event.extendedProps.status == 2 &&
                    arg.event.extendedProps.stage == 1
                  ? 'Color:blue'
                  : 'Color:black'
              "
              @click="toggle($event, arg.event)"
              aria-haspopup="true"
              aria-controls="overlay_panel"
            >
              {{ dateFormate(arg.event.extendedProps.start_time, "hh:mm") }} -
              {{ dateFormate(arg.event.extendedProps.end_time, "hh:mm") }}
              {{ arg.event.title }}</b
            >
          </template>
        </FullCalendar>
      </div>
    </div>
  </div>
  <Sidebar
    v-model:visible="data.scanDialog"
    style="width: 100%; min-width: 375px; min-height: 350px"
    position="bottom"
  >
    <div class="p-grid">
      <div>
        <select class="p-d-none" v-model="selectedDevice">
          <option v-for="device in devices" :key="device.label" :value="device">
            {{ device.label }}
          </option>
        </select>
        <qrcode-stream
          :constraints="{ deviceId: selectedDevice.deviceId }"
          style="height: 250px; width: 250px"
          class="p-mt-1"
          @init="onInit"
          @detect="onDecodeScan"
          :track="drawOutline"
          v-if="selectedDevice !== null"
        >
        </qrcode-stream>
      </div>
      <div class="p-col-7 p-grid p-ml-2">
        <div class="p-col-12" style="text-align: left">
          Organisation:{{
            data.employer != null ? data.employer.organisation_name : ""
          }}
        </div>
        <div class="p-col-12" style="text-align: left">
          ID:{{ data.employer != null ? data.employer.id : "" }}
        </div>
        <div class="p-col-12" style="text-align: left">
          EnglishName:{{
            data.employer != null ? data.employer.english_name : ""
          }}
        </div>
        <div class="p-col-12" style="text-align: left">
          Name:{{ data.employer != null ? data.employer.name : "" }}
        </div>
      </div>
    </div>
  </Sidebar>
  <Sidebar
    v-model:visible="data.chooseDialog"
    style="width: 70%; min-width: 375px"
    position="right"
  >
    <div style="margin-top: -80px">
      <EmployerView />
    </div>
  </Sidebar>
  <Sidebar
    v-model:visible="data.fastDialog"
    style="width: 375px; min-width: 375px"
    position="left"
  >
    <div class="p-grid">
      <div class="p-col-12">
        <Button
          disabled
          style="
            background: green !important;
            border: 1px solid green !important;
          "
          icon="pi pi-calendar"
          class="p-button-success"
        />

        <Calendar
          inputId="multiple"
          v-model="data.set_date"
          selectionMode="multiple"
          :manualInput="false"
          :showButtonBar="true"
        />
      </div>
      <div class="p-col-10" style="vertical-align: middle">
        <Dropdown
          style="width: 100%"
          v-model="data.AddShiftSearch"
          :options="data.selectShiftList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Shift').Title"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-col-2">
        <Button
          icon="pi pi-plus"
          class="p-button-rounded p-button-success p-button-sm"
          @click="SaveFastShift"
        />
      </div>
    </div>
  </Sidebar>
  <Dialog
    v-model:visible="data.chooseShiftDialog"
    header="班別設定"
    style="width: 650px"
  >
    Date：{{ dateFormate(data.shift_content_title, "YYYY-MM-DD") }}<br />
    <div
      v-if="store.state.user.role == 1"
      class="p-mt-1 p-mb-1"
      style="width: 100%"
    >
      <Button
        v-if="store.state.bouns_employer.length > 0"
        :label="i18n.$t('Currency').CurrencyTable.Delete"
        icon="pi pi-trash"
        class="p-button-danger"
        @click="
          () => {
            data.deleteAllDialog = true;
          }
        "
        :disabled="
          !data.selectedShiftRelation || !data.selectedShiftRelation.length
        "
      />
    </div>
    <div>
      <!-- <div class="p-grid">
        <div class="p-col-4">班別</div>
        <div class="p-col-4">時間</div>
        <div class="p-col-4"></div>
      </div> -->
      <div style="overflow: scroll; height: 200px">
        <DataTable
          :value="data.shift_contents"
          v-model:selection="data.selectedShiftRelation"
          :scrollable="true"
          responsiveLayout="scroll"
          scrollHeight="200px"
        >
          <Column
            v-if="store.state.user.role == 1"
            class="p-col-1"
            selectionMode="multiple"
          ></Column>
          <Column :field="'title'" :header="'Shift'"></Column>
          <Column :header="'Time'">
            <template #body="slotProps">
              {{ dateFormate(slotProps.data.start_time, "hh:mm") }}-{{
                dateFormate(slotProps.data.end_time, "hh:mm")
              }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                v-if="
                  store.state.user.role == 1 &&
                  store.state.bouns_employer.length > 0
                "
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-sm"
                @click="
                  () => {
                    data.shift = slotProps.data;
                    data.deleteDialog = true;
                    data.check_remove = true;
                  }
                "
              />
            </template>
          </Column>
        </DataTable>
        <!-- <div
          :key="index"
          v-for="(shift, index) in data.shift_contents"
          class="p-grid"
          :style="
            shift.status == 1
              ? 'Color:red;'
              : shift.status == 2 && shift.stage == 2
              ? 'Color:green'
              : shift.status == 2 && shift.stage == 1
              ? 'Color:blue'
              : 'Color:black'
          "
        >
          <div
            class="p-col-4"
            style="vertical-align: middle; line-height: 40px"
          >
            {{ shift.title }}
          </div>
          <div
            class="p-col-4"
            style="vertical-align: middle; line-height: 40px"
          >
            {{ dateFormate(shift.start, "hh:mm") }}-{{
              dateFormate(shift.end, "hh:mm")
            }}
          </div>
          <div v-if="store.state.user.role == 1" class="p-col-4">
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-sm"
              @click="
                () => {
                  data.shift = shift;
                  data.deleteDialog = true;
                  data.check_remove = true;
                }
              "
            />-->
        <!-- <Button
              icon="pi pi-play"
              class="p-button-rounded p-button-info p-ml-1 p-button-sm"
              @click="
                SettingConfirm(
                  3,
                  2,
                  1,
                  [shift.employer_id],
                  [shift.shift_id],
                  shift.start
                )
              "
            /> -->
        <!-- <Button
              icon="pi pi-play"
              class="p-button-rounded p-ml-1 p-button-sm"
              @click="
                SettingConfirm(
                  3,
                  2,
                  2,
                  [shift.employer_id],
                  [shift.shift_id],
                  shift.start
                )
              "
            /> -->
        <!-- <Button
              icon="pi pi-stop"
              class="p-button-rounded p-button-danger p-ml-1 p-button-sm"
              @click="
                SettingConfirm(
                  3,
                  1,
                  1,
                  [shift.employer_id],
                  [shift.shift_id],
                  shift.start
                )
              "
            /> -->
        <!-- </div>
        </div> -->
      </div>
      <div v-if="store.state.user.role == 1" class="p-grid">
        <div class="p-col-10" style="vertical-align: middle">
          <Dropdown
            style="width: 100%"
            v-model="data.AddShiftSearch"
            :options="data.selectShiftList"
            optionLabel="name"
            :filter="true"
            :placeholder="i18n.$t('Shift').Title"
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-col-2">
          <Button
            icon="pi pi-plus"
            class="p-button-rounded p-button-success p-button-sm"
            @click="SaveShift"
          />
          <Button
            v-if="store.state.shift_search == 'shift'"
            icon="pi pi-book"
            class="p-button-rounded p-button-warning p-button-sm p-ml-1"
            @click="Excel"
          />
        </div>
      </div>
    </div>
  </Dialog>
  <OverlayPanel
    ref="op"
    appendTo="body"
    :showCloseIcon="true"
    id="overlay_panel"
    style="width: 450px"
    :breakpoints="{ '960px': '75vw' }"
  >
    <h3>{{ data.shift_content.title }}</h3>
    日期：{{
      dateFormate(
        data.shift_content._def.extendedProps.start_time,
        "YYYY-MM-DD"
      )
    }}<br />
    開始時間：{{
      dateFormate(data.shift_content._def.extendedProps.start_time, "hh:mm")
    }}<br />
    結束時間：{{
      dateFormate(data.shift_content._def.extendedProps.end_time, "hh:mm")
    }}<br />
    <div
      v-if="store.state.user.role == 1"
      class="p-mt-3"
      style="width: 100%; text-align: right"
    >
      <!-- <Button
        class="p-button-danger p-button-sm"
        :label="i18n.$t('Employer').EmployerTable.Delete"
        @click="
          () => {
            data.deleteDialog = true;
            data.check_remove = false;
          }
        "
      /> -->
      <!-- <Button
        :label="i18n.$t('EmployerShift').Confirm"
        class="p-button-info p-ml-2 p-button-sm"
        @click="
          SettingConfirm(
            3,
            2,
            1,
            [data.shift_content._def.extendedProps.employer_id],
            [data.shift_content._def.extendedProps.shift_id],
            data.shift_content.start
          )
        "
      /> -->
      <!-- <Button
        :label="i18n.$t('EmployerShift').ClientConfirm"
        class="p-ml-2 p-button-sm"
        @click="
          SettingConfirm(
            3,
            2,
            2,
            [data.shift_content._def.extendedProps.employer_id],
            [data.shift_content._def.extendedProps.shift_id],
            data.shift_content.start
          )
        "
      /> -->
      <!-- <Button
        :label="i18n.$t('EmployerShift').ClientClose"
        class="p-button-danger p-ml-2 p-button-sm"
        @click="
          SettingConfirm(
            3,
            1,
            1,
            [data.shift_content._def.extendedProps.employer_id],
            [data.shift_content._def.extendedProps.shift_id],
            data.shift_content.start
          )
        "
      /> -->
    </div>
  </OverlayPanel>
  <!-- 單一刪除視窗 -->
  <Dialog
    v-model:visible="data.deleteDialog"
    :style="{ width: '450px' }"
    :header="i18n.$t('Shift').Confirm.Title"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="data.shift_content"
        >{{ i18n.$t("Shift").Confirm.Content }}
        <b>{{ data.shift_content.title }}</b
        >?</span
      >
      <span v-else
        >{{ i18n.$t("Shift").Confirm.Content }} <b>{{ data.shift.title }}</b
        >?</span
      >
    </div>
    <template #footer>
      <Button
        :label="i18n.$t('Shift').Confirm.No"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.deleteDialog = false"
      />
      <Button
        :label="i18n.$t('Shift').Confirm.Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="
          Remove(
            data.check_remove ? data.shift : data.shift_content,
            data.check_remove
          )
        "
      />
    </template>
  </Dialog>

  <!-- 全部刪除視窗 -->
  <Dialog
    v-model:visible="data.deleteAllDialog"
    :style="{ width: '450px' }"
    :header="i18n.$t('Shift').Confirm.Title"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="data.shift_content"
        >{{ i18n.$t("Shift").Confirm.Content }} ?</span
      >
      <span v-else>{{ i18n.$t("Shift").Confirm.Content }} ?</span>
    </div>
    <template #footer>
      <Button
        :label="i18n.$t('Shift').Confirm.No"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.deleteAllDialog = false"
      />
      <Button
        :label="i18n.$t('Shift').Confirm.Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="RemoveAll"
      />
    </template>
  </Dialog>

  <DataTable
    class="p-d-none"
    :value="data.excel.data"
    ref="dt"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :field="i18n.$t('EmployerShift').EmployerShiftTable.Shift.Field"
      :header="i18n.$t('EmployerShift').EmployerShiftTable.Shift.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('EmployerShift').EmployerShiftTable.People.Field"
      :header="i18n.$t('EmployerShift').EmployerShiftTable.People.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('EmployerShift').EmployerShiftTable.PeopleEnglish.Field"
      :header="i18n.$t('EmployerShift').EmployerShiftTable.PeopleEnglish.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('EmployerShift').EmployerShiftTable.Time.Field"
      :header="i18n.$t('EmployerShift').EmployerShiftTable.Time.Show"
    ></Column>
  </DataTable>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
