<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import * as XLSX from "xlsx/xlsx.mjs";
import Dialog from "primevue/dialog";
import User from "../../api/salary/auth/user";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import Toast from "primevue/toast";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Organisation from "../../api/salary/admin/organisation";
import Department from "../../api/salary/admin/department";
import Employer from "../../api/salary/admin/employer";
import Shift from "../../api/salary/admin/shift";
import ManagerShift from "../../api/salary/manager/shift";
import AttendanceRecords from "../../api/salary/admin/attendance_records";
import ManagerAttendanceRecords from "../../api/salary/manager/attendance_records";
import InputText from "primevue/inputText";
import { useToast } from "primevue/usetoast";
import { dateFormate } from "../../tool/formate";
import { useI18n } from "../../i18nPlugin";
import { useStore } from "vuex";

const toast = useToast();
const i18n = useI18n();
const store = useStore();

let departmentsAPI = new Department();
let userApi = new User();
let attendance_recordsAPI = new AttendanceRecords();
let manager_attendance_recordsAPI = new ManagerAttendanceRecords();

let employerAPI = new Employer();
let organisationAPI = new Organisation();
let shiftAPI = new Shift();
let manager_shiftAPI = new ManagerShift();

const data = reactive({
  selectedPunchCardRecords: [],
  time_diff: 0,
  punch_type: {
    id: 1,
    name: i18n.$t("AttendanceRecords").PunchType.Before,
  },
  shift: null,
  OrganisationSearch: null,
  selectShiftList: [],
  selectOrganisationList: [],
  selectDepartmentsList: [],
  DepartmentsSearch: null,
  today: new Date(),
  employer: null,
  employerLoading: false,
  employers: { data: null },
  totalRecords: 0,
  page: 1,
  page_num: 10,
  employerFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    organisation_id: { value: "", matchMode: "contains" },
    department_ids: { value: [], matchMode: "contains" },
    shift_id: { value: "", matchMode: "contains" },
    punch_date_begin: { value: "", matchMode: "contains" },
    punch_date_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  getShiftSelectList();
  if (store.state.user.role == 1) {
    getOrganisationSelectList();
    getDepartmentSelectList();
  }
  getAttendanceRecordsList();

  data.time_diff = 8 * 60 + new Date().getTimezoneOffset();
});

function getDepartmentSelectList() {
  departmentsAPI.getDepartmentSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectDepartmentsList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getOrganisationSelectList() {
  organisationAPI.getOrganisationSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectOrganisationList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getShiftSelectList() {
  if (store.state.user.role == 1) {
    shiftAPI.getShiftSelectList("").then((res) => {
      if (res.data.error_code == 0) {
        data.selectShiftList = res.data.data.datas;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  } else if (store.state.user.role == 2) {
    manager_shiftAPI.getShiftSelectList("").then((res) => {
      if (res.data.error_code == 0) {
        data.selectShiftList = res.data.data.datas;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  }
}

function getAttendanceRecordsList() {
  if (store.state.user.role == 1) {
    attendance_recordsAPI
      .getAttendanceRecordsList(
        "",
        data.employerFilters.id.value,
        data.employerFilters.punch_date_begin.value,
        data.employerFilters.punch_date_end.value,
        data.employerFilters.shift_id.value,
        1,
        data.employerFilters.name.value,
        data.employerFilters.organisation_id.value,
        data.employerFilters.department_ids.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          data.totalRecords = res.data.data.total_count;
          data.employers.data = res.data.data.datas;
          data.employerLoading = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
          data.employerLoading = false;
        }
      });
  } else if (store.state.user.role == 2) {
    manager_attendance_recordsAPI
      .getAttendanceRecordsList(
        "",
        data.employerFilters.id.value,
        data.employerFilters.punch_date_begin.value,
        data.employerFilters.punch_date_end.value,
        data.employerFilters.shift_id.value,
        1,
        data.employerFilters.name.value,
        data.employerFilters.organisation_id.value,
        data.employerFilters.department_ids.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          data.totalRecords = res.data.data.total_count;
          data.employers.data = res.data.data.datas;
          data.employerLoading = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
          data.employerLoading = false;
        }
      });
  }
}

function onPage(event) {
  data.page = event.page + 1;
}

watch(
  () => data.page,
  () => {
    data.employerLoading = true;
    getAttendanceRecordsList();
  }
);

watch(
  () => data.employerFilters.name.value,
  () => {
    data.employerLoading = true;
    getAttendanceRecordsList();
  }
);
watch(
  () => data.employerFilters.id.value,
  () => {
    data.employerLoading = true;
    getAttendanceRecordsList();
  }
);

watch(
  () => data.DepartmentsSearch,
  () => {
    data.employerLoading = true;
    data.employerFilters.department_ids.value =
      data.DepartmentsSearch == null ? "" : [data.DepartmentsSearch.id];
    getAttendanceRecordsList();
  }
);

watch(
  () => data.OrganisationSearch,
  () => {
    data.employerLoading = true;
    data.employerFilters.organisation_id.value =
      data.OrganisationSearch == null ? "" : data.OrganisationSearch.id;
    getAttendanceRecordsList();
  }
);

watch(
  () => data.shift,
  () => {
    data.employerLoading = true;
    data.employerFilters.shift_id.value =
      data.shift == null ? "" : data.shift.id;
    getAttendanceRecordsList();
  }
);

watch(
  () => data.date_search,
  async () => {
    data.employerLoading = true;
    if (data.date_search != null) {
      if (data.date_search[1] != null) {
        //     dateFormate(data.today, "YYYY-MM-DD") + "T00:00:00.000+08:00",
        //   dateFormate(data.today, "YYYY-MM-DD") + "T15:59:59.999+08:00",
        data.employerFilters.punch_date_begin.value =
          dateFormate(data.date_search[0], "YYYY-MM-DD") +
          "T00:00:00.000+08:00";
        data.employerFilters.punch_date_end.value =
          dateFormate(data.date_search[1], "YYYY-MM-DD") +
          "T15:59:59.999+08:00";
      } else {
        data.employerFilters.punch_date_begin.value = "";
        data.employerFilters.punch_date_end.value = "";
      }
    } else {
      data.employerFilters.punch_date_begin.value = "";
      data.employerFilters.punch_date_end.value = "";
    }
    getAttendanceRecordsList();
  }
);

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

function confirmDeleteSelected() {
  CheckUser();
  data.deleteSelectedDialog = true;
}

function deleteSelectedPunchCardRecords() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedPunchCardRecords.length; i++) {
    selectDelete.push(data.selectedPunchCardRecords[i].id);
  }
  attendance_recordsAPI.deleteAttendanceRecords(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Shift").DeleteSuccess,
        life: 3000,
      });

      data.employerLoading = true;
      data.selectedPunchCardRecords = [];
      getAttendanceRecordsList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function SaveExcel(e) {
  // console.log(e.target.files[0]);
  //上传文件后读取excel文件内容
  let file = e.target.files[0];
  const types = ["xlsx", "xls"];
  const arr = file.name.split(".");
  //判断文件是否为excel文件
  if (!types.find((item) => item === arr[arr.length - 1])) {
    alert("choose (xlsx or xls)");
    return;
  }
  let reader = new FileReader();
  //启动函数
  reader.readAsBinaryString(file);
  reader.onload = (e) => {
    // console.log(e);
    //workbook存放excel的所有基本信息
    let workbook = XLSX.read(e.target.result, {
      type: "binary",
      cellDates: true,
    });
    //定义sheetList中存放excel表格的sheet表，就是最下方的tab
    let sheetList = workbook.SheetNames;
    //读取文件内容，（第一个sheet里的内容）
    // range：设置从第几行开始读取内容
    let json = XLSX.utils.sheet_to_json(workbook.Sheets[sheetList[0]], {
      range: 1,
    });
    let importExcel = [];
    console.log(json);
    console.log(data.time_diff);
    for (let i = 0; i < json.length; i++) {
      importExcel.push({
        employer_id:
          json[i].employer_id != undefined ? json[i].employer_id : "",
        shift_id: json[i].shift_id != undefined ? json[i].shift_id : "",
        make_up: 1,
        time_diff: data.time_diff,
        punch_date:
          json[i].punch_date != undefined
            ? json[i].punch_date + "T00:00:00.000+08:00"
            : "",
        punch_time:
          json[i].punch_time != undefined ? new Date(json[i].punch_time) : "",
        after_punch_time:
          json[i].after_punch_time != undefined
            ? new Date(json[i].after_punch_time)
            : "",
      });
    }

    attendance_recordsAPI.batchAttendanceRecords(importExcel).then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("AttendanceRecords").AddSuccess,
          life: 3000,
        });
        data.employerLoading = true;
        getAttendanceRecordsList();
        data.import_excel_salary = false;
      } else {
        data.import_excel_salary = false;
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  };
}
</script>

<template>
  <Toast />
  <div>
    <div style="margin-top: 85px">
      <div class="p-grid">
        <div class="p-col-12" style="height: calc(100vh - 84px)">
          <DataTable
            :lazy="true"
            :resizableColumns="true"
            columnResizeMode="fit"
            :value="data.employers.data"
            :loading="data.employerLoading"
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="calc(100vh - 120px)"
            style="min-width: 375px"
            :paginator="true"
            :rows="10"
            :totalRecords="data.totalRecords"
            v-model:filters="data.employerFilters"
            v-model:selection="data.selectedPunchCardRecords"
            :row-hover="true"
            @page="onPage($event)"
            @filter="onFilter($event)"
            paginatorTemplate="FirstPageLink PageLinks LastPageLink"
          >
            <template #header>
              <div class="table-header p-grid">
                <Accordion class="accordion-custom p-col-12" :activeIndex="0">
                  <AccordionTab>
                    <template #header>
                      <i class="pi pi-search p-mr-1"></i>
                      <span>{{ i18n.$t("Shift").ShiftTable.Search }}</span>
                    </template>
                    <p style="text-align: left">
                      <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <InputText
                          style="width: 150px"
                          v-model="
                            data.employerFilters[
                              i18n.$t('Employer').EmployerTable.ID.Field
                            ].value
                          "
                          :placeholder="
                            i18n.$t('Employer').EmployerTable.ID.Show
                          "
                        />
                      </span>
                      <span class="p-input-icon-left p-ml-1">
                        <i class="pi pi-search" />
                        <InputText
                          style="width: 150px"
                          v-model="
                            data.employerFilters[
                              i18n.$t('Employer').EmployerTable.Name.Field
                            ].value
                          "
                          :placeholder="
                            i18n.$t('Employer').EmployerTable.Name.Show
                          "
                        />
                      </span>
                      <Dropdown
                        class="p-ml-1"
                        v-model="data.shift"
                        :options="data.selectShiftList"
                        optionLabel="name"
                        :filter="true"
                        :placeholder="i18n.$t('Shift').ShiftTable.Name.Show"
                        :showClear="true"
                      >
                        <template #value="slotProps">
                          <div
                            class="country-item country-item-value"
                            v-if="slotProps.value"
                          >
                            <div>{{ slotProps.value.name }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="country-item">
                            <div>{{ slotProps.option.name }}</div>
                          </div>
                        </template>
                      </Dropdown>
                      <Dropdown
                        v-if="store.state.user.role == 1"
                        class="p-ml-1"
                        v-model="data.OrganisationSearch"
                        :options="data.selectOrganisationList"
                        optionLabel="name"
                        :filter="true"
                        :placeholder="
                          i18n.$t('Employer').EmployerTable.Organisation.Show
                        "
                        :showClear="true"
                      >
                        <template #value="slotProps">
                          <div
                            class="country-item country-item-value"
                            v-if="slotProps.value"
                          >
                            <div>{{ slotProps.value.name }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="country-item">
                            <div>{{ slotProps.option.name }}</div>
                          </div>
                        </template>
                      </Dropdown>

                      <Dropdown
                        v-if="store.state.user.role == 1"
                        class="p-ml-1"
                        v-model="data.DepartmentsSearch"
                        :options="data.selectDepartmentsList"
                        optionLabel="name"
                        :filter="true"
                        :placeholder="
                          i18n.$t('Employer').EmployerDetailTable.Department
                            .Show
                        "
                        :showClear="true"
                      >
                        <template #value="slotProps">
                          <div
                            class="country-item country-item-value"
                            v-if="slotProps.value"
                          >
                            <div>{{ slotProps.value.name }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="country-item">
                            <div>{{ slotProps.option.name }}</div>
                          </div>
                        </template>
                      </Dropdown>
                    </p>
                    <p class="p-mt-1" style="text-align: left">
                      <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <Calendar
                          id="range"
                          v-model="data.date_search"
                          class="p-mr-1"
                          selectionMode="range"
                          dateFormat="yy/mm/dd"
                          :manualInput="false"
                          :showIcon="true"
                          :placeholder="
                            i18n.$t('AttendanceRecords').AttendanceRecordsTable
                              .PunchDate.Show
                          "
                          :showButtonBar="true"
                        />
                      </span>
                    </p>
                  </AccordionTab>
                </Accordion>

                <div
                  v-if="store.state.user.role == 1"
                  class="p-col-12 p-d-flex p-jc-end"
                >
                  <!-- <Button
                    :label="i18n.$t('Employer').ImportExcelSalary"
                    icon="pi pi-plus"
                    class="p-button-info p-mr-2"
                    @click="SaveExcel"
                  /> -->
                  <label class="p-button-info p-button p-component p-mr-2">
                    <input
                      id="upload_img"
                      style="display: none"
                      type="file"
                      @change="SaveExcel"
                    />
                    <i class="pi pi-book p-mr-1"></i>
                    {{ i18n.$t("Employer").ImportExcelSalary }}
                  </label>
                  <!-- <Button
                    :label="i18n.$t('Shift').ShiftTable.Delete"
                    icon="pi pi-trash"
                    class="p-button-danger"
                    @click="confirmDeleteSelected"
                    :disabled="
                      !data.selectedPunchCardRecords ||
                      !data.selectedPunchCardRecords.length
                    "
                  /> -->
                </div>
              </div>
            </template>
            <!-- <Column class="p-col-1" selectionMode="multiple"></Column> -->
            <Column
              class="p-col-1"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.EmployerID
                  .Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.EmployerID
                  .Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.EmployerName
                  .Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.EmployerName
                  .Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.Shift.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.Shift.Show
              "
            >
              <!-- <template #body="slotProps"
                >{{
                  data.selectShiftList.filter(
                    (shift) => shift.id === slotProps.data.shift_id
                  )[0].name
                }}
              </template> -->
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.PunchDate
                  .Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.PunchDate
                  .Show
              "
            >
              <template #body="slotProps"
                >{{
                  // dateFormate(slotProps.data.punch_date, "normal YYYY-MM-DD")
                  slotProps.data.punch_date.split("T")[0]
                }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.PunchTime
                  .Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.PunchTime
                  .Show
              "
            >
              <template #body="slotProps"
                >{{ dateFormate(slotProps.data.punch_time, "normal hh:mm") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable
                  .AfterPunchTime.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable
                  .AfterPunchTime.Show
              "
            >
              <template #body="slotProps"
                >{{
                  dateFormate(slotProps.data.after_punch_time, "normal hh:mm")
                }}
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <!-- 選擇刪除視窗 -->
  <Dialog
    v-model:visible="data.deleteSelectedDialog"
    :style="{ width: '450px' }"
    :header="i18n.$t('Shift').Confirm.Title"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>{{ i18n.$t("Shift").Confirm.SelectContent }}</span>
    </div>
    <template #footer>
      <Button
        :label="i18n.$t('Shift').Confirm.No"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.deleteSelectedDialog = false"
      />
      <Button
        :label="i18n.$t('Shift').Confirm.Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteSelectedPunchCardRecords"
      />
    </template>
  </Dialog>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
