<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
// import FaceDetect from "./FaceDetect.vue";
// import PeopleDetect from "./PeopleDetect.vue";
import User from "../../api/salary/auth/user";
import Toast from "primevue/toast";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Employer from "../../api/salary/admin/employer";
import Shift from "../../api/salary/admin/shift";
import AttendanceRecords from "../../api/salary/admin/attendance_records";
import InputText from "primevue/inputText";
import { useToast } from "primevue/usetoast";
import { dateFormate } from "../../tool/formate";
import { QrcodeStream } from "vue-qrcode-reader";
import { useI18n } from "../../i18nPlugin";
import { useStore } from "vuex";

const selectedDevice = ref(null);
const devices = ref([]);

const toast = useToast();
const i18n = useI18n();
const store = useStore();

let userApi = new User();
let attendance_recordsAPI = new AttendanceRecords();
let employerAPI = new Employer();
let shiftAPI = new Shift();

// const i18n = useI18n();
// const toast = useToast();
const data = reactive({
  camera: "rear",
  time_diff: 0,
  punch_type: {
    id: 1,
    name: i18n.$t("AttendanceRecords").PunchType.Before,
  },
  shift_type: null,
  selectShiftTypeList:
    store.state.user.id == 9
      ? [
          {
            id: 1,
            name: i18n.$t("AttendanceRecords").ShiftType.Normal,
          },
        ]
      : store.state.user.id == 4
      ? [
          {
            id: 2,
            name: i18n.$t("AttendanceRecords").ShiftType.Night,
          },
        ]
      : [
          {
            id: 1,
            name: i18n.$t("AttendanceRecords").ShiftType.Normal,
          },
          {
            id: 2,
            name: i18n.$t("AttendanceRecords").ShiftType.Night,
          },
        ],
  selectPunchTypeList: [
    {
      id: 1,
      name: i18n.$t("AttendanceRecords").PunchType.Before,
    },
    {
      id: 2,
      name: i18n.$t("AttendanceRecords").PunchType.After,
    },
  ],
  shift: null,
  selectShiftList: [],
  today: new Date(),
  employer: null,
  employerLoading: false,
  outJsonScan: "",
  employers: { data: null },
  totalRecords: 0,
  page: 1,
  page_num: 10,
  employerFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    organisation_id: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  getShiftSelectList();
  getAttendanceRecordsList();

  data.time_diff = 8 * 60 + new Date().getTimezoneOffset();

  devices.value = (await navigator.mediaDevices.enumerateDevices()).filter(
    ({ kind }) => kind === "videoinput"
  );

  if (devices.value.length > 0) {
    selectedDevice.value = devices.value[0];
  }
});

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
function onDecodeScan(qrCode) {
  data.outJsonScan = qrCode[0].rawValue;
  data.paused = true;
  setTimeout(() => {
    data.paused = false;
  }, 100);
}
function drawOutline(decodeData, context) {
  var points = [];
  for (let i = 0; i < decodeData[0].cornerPoints.length; i++) {
    switch (i) {
      case 0:
        points[0] = decodeData[0].cornerPoints[i];
        break;
      case 1:
        points[1] = decodeData[0].cornerPoints[i];
        break;
      case 2:
        points[2] = decodeData[0].cornerPoints[i];
        break;
      case 3:
        points[3] = decodeData[0].cornerPoints[i];
        break;
      default:
        break;
    }
  }

  context.font = "13px Arial";
  context.fillText(data.outJsonScan, points[0].x, points[0].y);
  context.lineWidth = 5;
  context.strokeStyle = "green";
  context.beginPath();
  context.moveTo(points[0].x, points[0].y);

  for (const { x, y } of points) {
    context.lineTo(x, y);
  }
  context.lineTo(points[0].x, points[0].y);
  context.closePath();
  context.stroke();
}

function getShiftSelectList() {
  shiftAPI.getShiftSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectShiftList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function addAttendanceRecords() {
  if (data.shift_type != null) {
    switch (data.shift_type.id) {
      case 1:
        attendance_recordsAPI
          .addAttendanceRecordsNormal(data.employer.id, data.time_diff)
          .then((res) => {
            if (res.data.error_code == 0) {
              data.page = 1;
              getAttendanceRecordsList();
              toast.add({
                severity: "success",
                summary: "Success Message",
                detail: "Success: " + data.employer.id + " Punch Card",
                life: 3000,
              });
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });
        break;
      case 2:
        attendance_recordsAPI
          .addAttendanceRecordsNight(data.employer.id, data.time_diff)
          .then((res) => {
            if (res.data.error_code == 0) {
              data.page = 1;
              getAttendanceRecordsList();
              toast.add({
                severity: "success",
                summary: "Success Message",
                detail: "Success: " + data.employer.id + " Punch Card",
                life: 3000,
              });
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });

        break;
    }
  } else {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail: "Choose ShiftType",
      life: 3000,
    });
  }
  // data.outJsonScan = "";
}
// function addAttendanceRecords() {
//   if (data.shift != null) {
//     attendance_recordsAPI
//       .addAttendanceRecords(data.employer.id, data.shift.id, 1, data.time_diff)
//       .then((res) => {
//         if (res.data.error_code == 0) {
//           data.page = 1;
//           getAttendanceRecordsList();
//           toast.add({
//             severity: "success",
//             summary: "Success Message",
//             detail:
//               "Success: " +
//               data.employer.id +
//               " Punch Card(" +
//               data.shift.name +
//               ")",
//             life: 3000,
//           });
//         } else {
//           toast.add({
//             severity: "warn",
//             summary: "Warn Message",
//             detail: res.data.error_text,
//             life: 3000,
//           });
//         }
//       });
//   } else {
//     toast.add({
//       severity: "warn",
//       summary: "Warn Message",
//       detail: "Choose Shift",
//       life: 3000,
//     });
//   }
//   // data.outJsonScan = "";
// }

function getStartAndEndOfDay(date) {
  // 設置日期時間為給定日期的0點
  //   console.log("------");
  //   console.log(dateFormate(date, "normal"));
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);

  // 設置日期時間為給定日期的23:59:59
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);

  return { startOfDay, endOfDay };
}

function getAttendanceRecordsList() {
  //   let Day = getStartAndEndOfDay(data.today);
  const yesterday = new Date(data.today);
  yesterday.setDate(data.today.getDate() - 1);
  attendance_recordsAPI
    .getAttendanceRecordsList(
      "",
      //   data.employerFilters.id.value,
      data.employer != null ? data.employer.id : "",
      //   Day.startOfDay,
      //   Day.endOfDay,
      data.shift_type == null || data.shift_type.id == 1
        ? dateFormate(data.today, "YYYY-MM-DD") + "T00:00:00.000+08:00"
        : dateFormate(yesterday, "YYYY-MM-DD") + "T00:00:00.000+08:00",
      dateFormate(data.today, "YYYY-MM-DD") + "T15:59:59.999+08:00",
      data.shift != null ? data.shift.id : "",
      1,
      data.employerFilters.name.value,
      data.employer != null ? data.employer.organisation_id : "",
      null,
      //   data.employerFilters.organisation_id.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.employers.data = res.data.data.datas;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

watch(
  () => data.outJsonScan,
  async () => {
    if (data.outJsonScan != "") {
      try {
        let qrcode = await JSON.parse(data.outJsonScan);
        employerAPI.getEmployerInfo(qrcode.id).then(async (res) => {
          if (res.data.error_code == 0) {
            data.employer = await res.data.data;
            addAttendanceRecords();
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      } catch (d) {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "Wrong Template",
          life: 3000,
        });
      }
    }
  }
);

function onPage(event) {
  data.page = event.page + 1;
}

watch(
  () => data.page,
  () => {
    getAttendanceRecordsList();
  }
);

function cameraOptions() {
  return {
    resolution: 1280,
    facingMode: "environment",
  };
}

function onInit(promise) {
  promise
    .then(() => {
      console.log("Successfully initilized! Ready for scanning now!");
    })
    .catch((error) => {
      if (error.name === "NotAllowedError") {
        this.errorMessage = "Hey! I need access to your camera";
      } else if (error.name === "NotFoundError") {
        this.errorMessage = "Do you even have a camera on your device?";
      } else if (error.name === "NotSupportedError") {
        this.errorMessage =
          "Seems like this page is served in non-secure context (HTTPS, localhost or file://)";
      } else if (error.name === "NotReadableError") {
        this.errorMessage =
          "Couldn't access your camera. Is it already in use?";
      } else if (error.name === "OverconstrainedError") {
        this.errorMessage =
          "Constraints don't match any installed camera. Did you asked for the front camera although there is none?";
      } else {
        this.errorMessage = "UNKNOWN ERROR: " + error.message;
      }
    });
}
</script>

<template>
  <Toast />
  <!-- <div
    style="
      margin-top: 85px;
      display: flex;
      /* 水平置中 */
      justify-content: center;
      /* 垂直置中 */
      align-items: center;
    "
  >
    <PeopleDetect />
  </div> -->
  <!-- <FaceDetect /> -->
  <div>
    <div style="margin-top: 85px">
      <div class="p-grid">
        <div
          class="p-col-7"
          style="height: calc(100vh - 84px); border-right: 1px solid"
        >
          <InputText
            class="p-mt-3 p-mb-1"
            style="width: 50%"
            v-model="data.outJsonScan"
            id="name"
            required="true"
          />
          <Dropdown
            style="width: 50%"
            v-model="data.shift_type"
            :options="data.selectShiftTypeList"
            optionLabel="name"
            :filter="true"
            :placeholder="i18n.$t('Shift').ShiftTable.Name.Show"
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
          <!--  <Dropdown
            style="width: 50%"
            v-model="data.shift"
            :options="data.selectShiftList"
            optionLabel="name"
            :filter="true"
            :placeholder="i18n.$t('Shift').ShiftTable.Name.Show"
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>-->
          <!-- <Dropdown
            style="width: 20%"
            v-model="data.punch_type"
            :options="data.selectPunchTypeList"
            optionLabel="name"
            :placeholder="
              i18n.$t('AttendanceRecords').AttendanceRecordsTable.PunchType.Show
            "
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown> -->
          <select v-model="selectedDevice">
            <option
              v-for="device in devices"
              :key="device.label"
              :value="device"
            >
              {{ device.label }}
            </option>
          </select>
          <qrcode-stream
            :paused="data.paused"
            :constraints="{ deviceId: selectedDevice.deviceId }"
            style="height: 55vh"
            class="p-mt-1"
            :camera="cameraOptions"
            @init="onInit"
            @detect="onDecodeScan"
            :track="drawOutline"
            v-if="selectedDevice !== null"
          >
          </qrcode-stream>
          <div class="p-grid p-mt-3" style="width: 100%; border-top: 1px solid">
            <div class="p-col-12" style="text-align: left">
              Organisation:{{
                data.employer != null ? data.employer.organisation_name : ""
              }}
            </div>
            <div class="p-col-12" style="text-align: left">
              ID:{{ data.employer != null ? data.employer.id : "" }}
            </div>
            <div class="p-col-12" style="text-align: left">
              EnglishName:{{
                data.employer != null ? data.employer.english_name : ""
              }}
            </div>
            <div class="p-col-12" style="text-align: left">
              Name:{{ data.employer != null ? data.employer.name : "" }}
            </div>
          </div>
        </div>
        <div class="p-col-5" style="height: calc(100vh - 84px)">
          <h3 style="border-bottom: 1px solid">
            Date:{{ dateFormate(data.today, "normal YYYY-MM-DD") }}
          </h3>
          <DataTable
            :lazy="true"
            :resizableColumns="true"
            columnResizeMode="fit"
            :value="data.employers.data"
            :loading="data.employerLoading"
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="calc(100vh - 120px)"
            style="min-width: 375px"
            :paginator="true"
            :rows="10"
            :totalRecords="data.totalRecords"
            v-model:filters="data.employerFilters"
            :row-hover="true"
            @page="onPage($event)"
            @filter="onFilter($event)"
            paginatorTemplate="FirstPageLink PageLinks LastPageLink"
          >
            <Column
              class="p-col-1"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.EmployerID
                  .Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.EmployerID
                  .Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.EmployerName
                  .Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.EmployerName
                  .Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.Shift.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.Shift.Show
              "
            >
              <!-- <template #body="slotProps"
                >{{
                  data.selectShiftList.filter(
                    (shift) => shift.id === slotProps.data.shift_id
                  )[0].name
                }}
              </template> -->
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.PunchTime
                  .Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable.PunchTime
                  .Show
              "
            >
              <template #body="slotProps"
                >{{ dateFormate(slotProps.data.punch_time, "normal hh:mm") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable
                  .AfterPunchTime.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsTable
                  .AfterPunchTime.Show
              "
            >
              <template #body="slotProps"
                >{{
                  dateFormate(slotProps.data.after_punch_time, "normal hh:mm")
                }}
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
