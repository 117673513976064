import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class AttendanceRecords {
  getAttendanceRecordsReportNew(
    employer_id,
    employer_organisation_id,
    punch_date_begin,
    punch_date_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/manager/attendance-records/records/detail/list`,
      {
        params: {
          employer_id: employer_id,
          employer_organisation_id: employer_organisation_id,
          punch_date_begin: punch_date_begin,
          punch_date_end: punch_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getAttendanceRecordsReport(
    employer_id,
    employer_organisation_id,
    punch_date_begin,
    punch_date_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/manager/attendance-records/records/list`,
      {
        params: {
          employer_id: employer_id,
          employer_organisation_id: employer_organisation_id,
          punch_date_begin: punch_date_begin,
          punch_date_end: punch_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getAttendanceRecordsInfo(id) {
    return axios.get(
      `/api/manager/attendance-records/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getAttendanceRecordsList(
    id,
    employer_id,
    punch_date_begin,
    punch_date_end,
    shift_id,
    make_up,
    employer_name,
    employer_organisation_id,
    employer_department_ids,
    page,
    page_num
  ) {
    return axios.get(
      `/api/manager/attendance-records/list`,
      {
        params: {
          id: id,
          employer_id: employer_id,
          punch_date_begin: punch_date_begin,
          punch_date_end: punch_date_end,
          shift_id: shift_id,
          make_up: make_up,
          employer_name: employer_name,
          employer_organisation_id: employer_organisation_id,
          employer_department_ids: employer_department_ids,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
}
